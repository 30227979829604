import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "loading-of-chat"
    }}>{`Loading of chat`}</h1>
    <h2 {...{
      "id": "fetch-agent-availability-on-load"
    }}>{`Fetch agent availability on load`}</h2>
    <p>{`You can alter the behavior of chat by adding `}<inlineCode parentName="p">{`offline`}</inlineCode>{` function to cobrowse.create. This function is run when no agents are available at the team the call is made and returns name of the team.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8">
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          offline: function(teamName) {
            console.info('Chat Client no online Agents for Team', teamName);
          };
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>
    <h2 {...{
      "id": "launch-chat-and-send-message-from-another-button"
    }}>{`Launch chat and send message from another button`}</h2>
    <p>{`You can launch the smilee chat and send first message  by adding getActionTrigger to cobrowse.create options and function like kickOpenFunc above the script. This script can use users email and name as options, these are optional.
firstMessage is the only required property. If firstMessage is empty string "" the chat window will open, but if firstMessage is set the message will be send automatically when chat window is loaded.
The example below launches a new chat from button with id open-chat, fetches name and email from fields with id:s and sends 'Interested about Smilee.io Chat!' message as initial message in chat.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8">
    // Function to handle the opening of the chat
    var kickOpenFunc = function(props) {
      console.log("Placeholder kickOpenFunc props:", props);
    };
    $("#open-chat").on("click", function() {
      kickOpenFunc({
        email: $("#e-mail").val(), // optional
        name: $("#my-name").val(), // optional
        firstMessage: "Interested about Smilee.io Chat!"
      });
    });
    // End of function to handle opening

    (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          // New option to cobrowse.create
          getActionTrigger = function(myCallback) {
            kickOpenFunc = myCallback;
          };
          // End of new option
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>
    <h2 {...{
      "id": "change-state-of-autopopup-of-chat"
    }}>{`Change state of autopopup of chat`}</h2>
    <p>{`Smilee is exposing setAutoPopupDone and resetAutoPopupDone callbacks in success callback of the cobrowse.create.
With these functions you can control the state of the autopopup of chat since in default case the autopopup is done only once.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8">
  var mySetAutopopupDone  // Create a variable where to store the callback
  var myResetAutopopupDone
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          success: function() {
            mySetAutopopupDone = setAutoPopupDone(), // Store the callback to the variable
            myResetAutopopupDone = resetAutoPopupDone()
          };
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      